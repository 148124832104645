import {createRouter, createWebHashHistory} from 'vue-router'
import main from '../views/main.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: main
    }
]

const router = createRouter({
    mode: 'history',
    history: createWebHashHistory(),
    routes
})

export default router
