<template>
  <div class="oj-report">
    <header>
      <img src="../assets/images/logo.svg">
    </header>
    <main>

      <section v-if="info.status === '1'">
        <img src="../assets/images/success.png">
        <p>支付成功</p>
        <p>-{{ info.amount }}</p>
      </section>


      <section v-else>
        <img src="../assets/images/fail.png">
        <p>支付失敗</p>
      </section>

      <ul>
        <li>
          <label>用戶名</label>
          <span>{{ info.user_name }}</span>
        </li>
        <li>
          <label>訂單號</label>
          <span>{{ info.order_id }}</span>
        </li>
        <li>
          <label>訂單金額</label>
          <span>{{ info.amount }}</span>
        </li>
        <li>
          <label>訂單描述</label>
          <span>{{ info.description }}</span>
        </li>
        <li>
          <label>支付時間</label>
          <span>{{ info.pay_time }}</span>
        </li>
      </ul>
    </main>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      info: {
        status: 1,
        pay_time: '-',
        amount: '-',
        user_name: '-',
        description: '-',
        order_id: '-'
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const L = location.href;
      let params = decodeURIComponent(L.split('?')[1]);
      if (params.indexOf('#/') !== -1) {
        params = params.replace('#/', '')
      }
      this.info.status = this.fetchKeyValue(params, 'status', '&');
      if (this.info.status === '1') {
        this.info.pay_time = this.fetchKeyValue(params, 'pay_time', '&');
        this.info.amount = this.fetchKeyValue(params, 'amount', '&');
        this.info.user_name = this.fetchKeyValue(params, 'user_name', '&');
        this.info.description = this.fetchKeyValue(params, 'description', '&');
        this.info.order_id = this.fetchKeyValue(params, 'order_id', '&')
      }
    },
    fetchKeyValue(str, key, sp) {
      if (str.indexOf(`${key}=`) !== -1) {
        let result;
        let s = str.substring(str.indexOf(`${key}=`));
        if (s.indexOf(sp) !== -1) {
          result = s.substring(s.indexOf(`${key}=`) + key.length + 1, s.indexOf(sp));
        } else {
          if (s.indexOf(`${key}=`) !== -1) {
            result = s.substring(s.indexOf(`${key}=`) + key.length + 1);
          }
        }
        console.log(result)
        return result;
      }
    }
  }
}
</script>

<style scoped>
@import "../assets/css/reset.css";

.oj-report {
  background-color: #fff;
  padding: 1.1rem 8%;
}

.oj-report > header {
  padding-bottom: .9rem;
  border-bottom: 1px solid #E4E4E4;
}

.oj-report > main > section {
  text-align: center;
  border-bottom: 1px solid #E4E4E4;
  padding: 3.3rem 0;
}

.oj-report > main > section > p {
  padding: .5rem 0;
}

.oj-report > main > section > p:nth-of-type(1) {
  color: #999999;
  font-size: 14px;
}

.oj-report > main > section > p:nth-of-type(2) {
  color: #000000;
  font-weight: bold;
  letter-spacing: .08rem;
  font-size: 30px;
}

.oj-report > main > ul {
  padding-top: 1.5rem;
}

.oj-report > main > ul > li {
  line-height: 2;
}

.oj-report > main > ul > li > label {
  display: inline-block;
  font-size: 12px;
  color: #999999;
  width: 6.5rem;
}

.oj-report > main > ul > li > span {
  display: inline-block;
  font-size: 12px;
  color: #393939;
}
</style>
